import * as storage from '../utils/storage';
import Settings from '../domain/Settings';
import {getGigyaId} from '../utils/gigya';
import {getCxenseProperties} from './cxenseService';
import {hasPurposesConsent, hasUserProfilingConsent, onConsentReady} from './privacyService';
import * as performance from '../utils/performance';
import MD5 from 'crypto-js/md5';

type UserIds = {
	value: string
	rdmoi: string
	expiration: string
	idCount: number
};

const PROFILE_SERVICE_URL = 'https://dmoi.api.dpgmedia.net/dmoi',
	DMOI_STORAGE_KEY = 'advert-dmoi',
	DMOI_FETCH_TIMEOUT = 1000,
	DEFAULT_INVALIDATION_HOUR = 5,
	DEFAULT_CACHE_DAYS = 1,
	ROTATING_FREQUENCY_IN_DAYS = 7;


let userIds: UserIds = null,
	enableReloading = false;

export async function init(): Promise<void> {
	if (!Settings.getInstance().features.dmoi) {
		userIds = null;

		return;
	}

	userIds = _getFromStorage();

	const identifiers = _getIdentifiers();

	if (shouldRefetch(identifiers)) {
		await loadDMOI(identifiers);
	}
}

async function loadDMOI(identifiers: Record<string, string>): Promise<void> {
	const idCount = Object.keys(identifiers).length;
	let dmoi;

	if (idCount === 0) {
		enableReloading = true;
		dmoi = null;
	} else {
		dmoi = await _fetchDMOI(identifiers);
	}

	userIds = {
		value: dmoi,
		rdmoi: dmoi ? _calculateRotatingUserId(dmoi) : null,
		expiration: _getExpirationDate(),
		idCount
	};

	_cacheUserIds();
}

function _getIdentifiers(): Record<string, string> {
	return Object.fromEntries(
		Object.entries({
			'pw_id': storage.getFromCookie('authId'),
			'gigya_id': getGigyaId(),
			'cxense_id': getCxenseProperties()?.id
		}).filter(([, v]) => v) as [string, string][]
	);
}

async function _fetchDMOI(identifiers: Record<string, string>): Promise<string | null> {
	try {
		performance.mark('user - dmoi - start');

		const {
				apiKey,
				url = PROFILE_SERVICE_URL
			} = Settings.getInstance().dmoi,
			abortController = new AbortController(),
			abortTimeout = setTimeout(() => {
				performance.mark('user - dmoi - timeout');
				abortController.abort('Timed out');
			}, DMOI_FETCH_TIMEOUT),
			response = await window.fetch(url, {
				'method': 'POST',
				'headers': {
					'x-api-key': apiKey
				},
				'body': JSON.stringify(identifiers),
				'signal': abortController.signal
			});

		clearTimeout(abortTimeout);

		return (await response.json()).dmoi;
	} catch (error) {
		console.error('[ADVERT] Could not fetch DMOI', error);

		return null;
	} finally {
		performance.mark('user - dmoi - end');
	}
}

function _cacheUserIds() {
	onConsentReady(() => {
		if (hasPurposesConsent([1])) {
			storage.put(DMOI_STORAGE_KEY, JSON.stringify(userIds));
		}
	});
}

function _getExpirationDate(): string {
	const currDate = new Date(),
		dmoiCacheSettings = Settings.getInstance().dmoi?.cache;

	currDate.setDate(currDate.getDate() + (dmoiCacheSettings?.duration ?? DEFAULT_CACHE_DAYS));
	currDate.setUTCHours(dmoiCacheSettings?.invalidationHour ?? DEFAULT_INVALIDATION_HOUR, 0, 0, 0);

	return currDate.toISOString();
}

function _getFromStorage(): UserIds | null {
	const storageValue = storage.get(DMOI_STORAGE_KEY);

	if (!storageValue) {
		return null;
	}

	return JSON.parse(storageValue);
}

function shouldRefetch(identifiers: Record<string, string>): boolean {
	return !userIds?.value
		|| new Date(userIds.expiration) < new Date()
		|| Object.keys(identifiers).length > userIds.idCount;
}

function _calculateRotatingUserId(dmoi: string) {
	const date = new Date(),
		dayOfYear = _getDayOfYear(date),
		year = date.getFullYear().toString(),
		rotation = Math.floor(dayOfYear / ROTATING_FREQUENCY_IN_DAYS).toString();

	return MD5(dmoi + year + rotation + '53nN3cr0u65').toString();
}

function _getDayOfYear(date: Date) {
	const currentDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
		firstDayOfYear = Date.UTC(date.getFullYear(), 0, 0);

	return (currentDate - firstDayOfYear) / 24 / 60 / 60 / 1000;
}

export function getUserId(): string {
	if (!hasUserProfilingConsent()) {
		return null;
	}

	return userIds?.value ?? null;
}

export function isUserLoggedIn(): boolean {
	return !!getGigyaId();
}

export function getRandomizedUserId(): string {
	if (!hasUserProfilingConsent()) {
		return null;
	}

	return userIds?.rdmoi ?? null;
}

export async function onIdentityUpdate(): Promise<void> {
	if (
		userIds?.value // Already fetched
		|| !enableReloading // Never tried before
		|| !Settings.getInstance().features.dmoi // Not enabled
	) {
		return;
	}

	await loadDMOI(_getIdentifiers());
}
