import Settings from '../domain/Settings';
import {hasPurposesConsent} from '../services/privacyService';
import * as storage from '../utils/storage';
import {getCxenseProperties} from '../services/cxenseService';
import * as performance from '../utils/performance';
import {loadResource, ResourceType} from '../utils/resource-util';

declare global {
	interface Window {
		firstId?: {
			customData?: FirstIdCustomData;
		};
	}
}

type FirstIdCustomData = {
	cxenseId?: string;
	pwdId?: string;
	accountId?: string;
};

let identifiers: FirstIdCustomData;

export function init(): void {
	const settings = Settings.getInstance();

	if (!settings.features?.firstId)
		return;

	if (!hasConsent())
		return;

	identifiers = getIdentifiers();

	performance.mark('firstid - start');

	document.removeEventListener('firstid:initialize', onFirstIdInit);
	document.addEventListener('firstid:initialize', onFirstIdInit);

	setIdentifiers();

	loadResource({
		type: ResourceType.Script,
		content: 'https://cdn.first-id.fr/sdk/loader/loader-latest-flex-no-tcf.min.js'
	});
}

function setIdentifiers() {
	window.firstId = {
		customData: identifiers,
	};
}

function onFirstIdInit() {
	performance.mark('firstId - init');
}

function hasConsent(): boolean {
	return hasPurposesConsent([1, 2, 3, 4, 7, 9, 10]);
}

function getIdentifiers(): FirstIdCustomData {
	return Object.fromEntries(
		Object.entries({
			pwdId: storage.getFromCookie('authId'),
			cxenseId: getCxenseProperties()?.id
		}).filter(([, v]) => v)
	);
}
