import {publish} from '../utils/pubsub';

const authKey = Math.random().toString(36).slice(2);

function isValidOrigin(e: MessageEvent) {
	return e.origin === window.location.origin
		|| /^https?:\/\/.+\.adnxs(-simple)?\.com$/.test(e.origin)
		|| /^https?:\/\/adsdk\.microsoft.com$/.test(e.origin)
		|| /^https?:\/\/adsdk\.bing.net$/.test(e.origin);
}

window.addEventListener('message', (e) => {
	if (typeof e.data !== 'string' || !isValidOrigin(e)) {
		return;
	}

	const data = (function(raw) {
		try {
			return JSON.parse(raw);
		} catch (err) {
			return false;
		}
	}(e.data));

	if (data && data.type === 'advertCallback' && typeof data.action === 'string') {
		if (data.authKey !== authKey) {
			console.error('[ADVERT] Invalid authKey on callback. Received:', authKey);

			return;
		}

		publish(data.action, data.options);
	}
});

export function getAuthKey() {
	return authKey;
}
