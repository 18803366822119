import * as storage from '../utils/storage';

const EXPERIMENTS_STORAGE_KEY = 'advert-experiments';

let experimentGroups: Array<Experiment> = [],
	experiments: ExperimentsConfig = [];

export function init(cfg: ExperimentsConfig): void {
	experiments = cfg ?? [];
	experimentGroups = [];

	const storedExperimentGroups = _getExperimentGroupsFromStorage();

	storedExperimentGroups.forEach((expGroup) => {
		const experiment = experiments.find((exp) => exp.name === expGroup.name);

		if (experiment) {
			experimentGroups.push({
				'name': expGroup.name,
				'group': expGroup.group,
				'expiration': _getExpirationDate()
			});

			return;
		}

		if (new Date(expGroup.expiration) >= new Date()) {
			experimentGroups.push(expGroup);
		}
	});

	experiments.forEach((experiment) => {
		const experimentGroup = experimentGroups.find((expGroup) => expGroup.name === experiment.name);

		if (!experimentGroup) {
			const group = _createExperimentGroup(experiment);

			experimentGroups.push(group);
		}
	});

	_storeExperimentGroups();
}

function _createExperimentGroup(experiment: ExperimentGroups) {
	const totalWeight = experiment.groups.reduce((agg, group) => {
		agg += group.weight;

		return agg;
	}, 0);

	let number = Math.floor(Math.random() * totalWeight);

	for (const group of experiment.groups) {
		if (number < group.weight) {
			return {
				'name': experiment.name,
				'group': group.name,
				'expiration': _getExpirationDate()
			};
		}

		number -= group.weight;
	}
}

function _storeExperimentGroups(): void {
	storage.put(EXPERIMENTS_STORAGE_KEY, JSON.stringify(experimentGroups));
}

function _getExperimentGroupsFromStorage(): Array<Experiment> {
	const storageValue = storage.get(EXPERIMENTS_STORAGE_KEY);

	if (!storageValue) {
		return [];
	}

	return JSON.parse(storageValue);
}

export function getActiveExperiments(): Array<Experiment> {
	return experimentGroups.map((experimentGroup) => ({
		'name': experimentGroup.name,
		'group': experimentGroup.group
	})).filter((experimentGroup) => {
		return experiments.some((e) => e.name === experimentGroup.name);
	});
}

function _getExpirationDate(): string {
	const currDate = new Date();

	currDate.setDate(currDate.getDate() + 7);
	currDate.setUTCHours(0, 0, 0, 0);

	return currDate.toISOString();
}
