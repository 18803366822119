import breakpoints from '../services/breakpoints';
import sizeMappings from '../services/sizeMappings';
import * as adManager from '../services/adManager';
import pubsub from '../utils/pubsub';
import * as perf from '../utils/performance';
import {refreshSlots} from '../services/slotLoader';

const ORIGINAL_ID_ATTRIBUTE = 'data-advert-orig-id',
	STATE_ATTRIBUTE = 'data-advert-slot-state',
	CLOSABLE_ATTRIBUTE = 'data-advert-closable',
	IS_EMPTY_ATTRIBUTE = 'data-advert-slot-empty',
	LABEL_ATTRIBUTE = 'data-advert-label';

function _validateSlotConfig(slotConfig: SlotConfig) {
	function _validatePlacement(placement: string) {
		if (typeof placement !== 'string' && typeof placement !== 'number') {
			throw new Error('\'placement\' is required and should be a number, string or an object containing numbers or strings for each breakpoint.');
		}
	}

	if (typeof slotConfig.name !== 'string') {
		throw new Error('\'name\' is required and should be a string in slot config');
	}

	if (typeof slotConfig.placement === 'object') {
		Object.keys(breakpoints.getBreakpoints()).forEach((bp) => {
			_validatePlacement((slotConfig.placement as Record<string, string>)[bp]);
		});
	} else {
		_validatePlacement(slotConfig.placement);
	}

	if (typeof slotConfig.sizeMapping !== 'string') {
		throw new Error('\'sizeMapping\' is required and should be a string inn slot config');
	}
}

function _sizesMatch(a: Array<number | string>, b: Array<number | string>) {
	return (a[0] === b[0] || a[0] === '*') && (a[1] === b[1] || a[1] === '*');
}

export enum MoveBehaviour {
	MOVE = 'move',
	COPY = 'copy'
}

export default class Slot {
	state: SlotState;
	name: string;
	domId: string;
	memberId: number;
	targeting: Record<string, unknown>;
	placement: BreakpointOverridable<string>;
	sizeMapping: string;
	sizeRemapping: Record<string, Array<[Size, Size]>>;
	preload: BreakpointOverridable<boolean>;
	node: HTMLElement;
	renderBreakpoint: string;
	closeable: boolean;
	lazyLoad: BreakpointOverridable<boolean>;
	lazyLoadThreshold: BreakpointOverridable<number | string>;
	refreshSettings: RefreshSettings;
	isEmpty: boolean | null;
	isMediated: boolean;
	isMediationEmpty: boolean | null;
	forceCreativeId?: number;
	showAdvertisementLabel?: boolean;

	moveBehaviour: MoveBehaviour;
	copies?: Array<Slot>;

	platformName?: string;

	// Ad Data
	adResponse?: XandrAdObject;
	auctionId?: string;
	size: [number, number];
	originalSize?: [number, number];
	differentSeatKeywords: string[];

	constructor(slotConfig: SlotConfig) {
		_validateSlotConfig(slotConfig);

		this.name = slotConfig.name;
		this.domId = _generateRandomSuffix(this.name);
		this.placement = slotConfig.placement;
		this.sizeMapping = slotConfig.sizeMapping;
		this.sizeRemapping = slotConfig.sizeRemapping;
		this.targeting = Object.assign({}, slotConfig.targeting);
		this.preload = slotConfig.preload ?? true;
		this.closeable = Boolean(slotConfig.closeable);
		this.node = null;
		this.renderBreakpoint = null;
		this.isEmpty = null;
		this.isMediated = false;

		this.lazyLoad = slotConfig.lazyLoad ?? false;
		this.lazyLoadThreshold = slotConfig.lazyLoadThreshold;

		this.refreshSettings = slotConfig.refreshSettings;

		this.moveBehaviour = slotConfig.moveBehaviour ?? MoveBehaviour.MOVE;
		if (this.moveBehaviour === MoveBehaviour.COPY) {
			this.copies = [];
		}

		this.memberId = slotConfig.memberId;
		this.differentSeatKeywords = slotConfig.differentSeatKeywords;

		this.showAdvertisementLabel = slotConfig.showAdvertisementLabel ?? false;

		this.state = {} as SlotState;

		this.#setUrlOverrides();

		this.#publishStateEvents('created');
	}

	setDefined(value: boolean): void {
		this.#setMainState('defined', value);
	}

	setRequested(value: boolean): void {
		this.#setMainState('requested', value);
	}

	setReceived(value: boolean): void {
		this.#setMainState('received', value);
	}

	setRendered(value: boolean): void {
		this.#setMainState('rendered', value);
	}

	setShowTagCalled(value: boolean): void {
		this.state.showTagCalled = value;

		pubsub.publish(`slot.${this.name}`, this);
	}

	setMediated(value: boolean, empty?: boolean): void {
		this.isMediated = value;
		this.isMediationEmpty = value ? empty : null;

		pubsub.publish(`slot.${this.name}`, this);
	}

	setError(errorType: string): void {
		this.state.error = errorType;
		this.#publishStateEvents('error');
	}

	#setMainState(key: string, value: boolean) {
		(this.state as Record<string, boolean | string>)[key] = value;

		this.#updateNodeState();

		if (value) {
			this.#publishStateEvents(key);
		}
	}

	#updateNodeState() {
		if (this.node) {
			const nodeState = ['rendered', 'received', 'requested', 'defined'].find((state) => (this.state as Record<string, boolean | string>)[state]) ?? 'created';

			this.node.setAttribute(STATE_ATTRIBUTE, nodeState);

			if (this.isEmpty) {
				this.node.setAttribute(IS_EMPTY_ATTRIBUTE, '');
			} else {
				this.node.removeAttribute(IS_EMPTY_ATTRIBUTE);
			}
		}
	}

	#publishStateEvents(state: string) {
		perf.mark(`xandr - slot - ${this.name} - ${state}`);

		pubsub.publish(`slot.${state}`, this);
		pubsub.publish(`slot.${this.name}`, this);
		pubsub.publish(`slot.${this.name}.${state}`, this);
	}

	#updateRenderData(xandrAd: XandrAdObject) {
		this.adResponse = xandrAd;

		if (typeof xandrAd.nobid !== 'undefined') {
			this.isEmpty = Boolean(xandrAd.nobid);
		}

		// Legacy support, native renderer uses this
		this.auctionId = xandrAd.auctionId as string;

		this.size = [xandrAd.width as number, xandrAd.height as number];

		this.renderBreakpoint = breakpoints.getCurrentBreakpoint();

		this.#updateNodeState();
	}

	#remapSize() {
		const remapSize = this.getRemapSize(this.size, this.adResponse.adType as string);

		if (remapSize) {
			adManager.resizeSlot(this, remapSize);
			this.originalSize = this.size;
			this.size = remapSize;
		}
	}

	getRemapSize(size: [number, number], adType: string): [number, number] | null {
		const sizeRemapping: Record<string, Array<[Size, Size]>> | null = this.sizeRemapping ?? sizeMappings.getSizeMapping(this.sizeMapping)?.sizeRemapping;

		return sizeRemapping?.[adType]?.find(([before]) => _sizesMatch(before, size))?.[1] ?? null;
	}

	getPreload() {
		// Breakpoint based preload
		if (typeof this.preload === 'object') {
			const currentBp = breakpoints.getCurrentBreakpoint();

			return typeof this.preload[currentBp] === 'undefined' ? this.preload.default : this.preload[currentBp];
		}

		return this.preload;
	}

	updateNode(node: HTMLElement) {
		this.removeNode();

		this.node = node;
		this.node.setAttribute(ORIGINAL_ID_ATTRIBUTE, this.node.id);
		this.node.id = this.domId;

		if (this.showAdvertisementLabel) {
			this.node.setAttribute(LABEL_ATTRIBUTE, '');
		}

		this.#updateNodeState();

		pubsub.publish(`slot.${this.name}`, this);
	}

	removeNode() {
		if (this.node) {
			this.node.id = this.node.getAttribute(ORIGINAL_ID_ATTRIBUTE);
			this.node.removeAttribute(ORIGINAL_ID_ATTRIBUTE);
			this.node.removeAttribute(IS_EMPTY_ATTRIBUTE);
			this.node.removeAttribute(STATE_ATTRIBUTE);
			this.node.removeAttribute(CLOSABLE_ATTRIBUTE);
			this.node.removeAttribute(LABEL_ATTRIBUTE);
			this.node.innerHTML = '';
			this.node = null;
		}
	}

	refresh(reason = 'other') {
		refreshSlots([this], reason);

		return this;
	}

	setAdData(adObj: XandrAdObject) {
		this.#updateRenderData(adObj);
		this.#remapSize();

		pubsub.publish(`slot.${this.name}`, this);
	}

	#setUrlOverrides() {
		const placementOverride = this.#getUrlOverrideEntry('advert_override_placement');

		if (placementOverride) {
			this.placement = placementOverride;
		}

		const forcedCreativeOverride = this.#getUrlOverrideEntry('advert_force_creative');

		if (forcedCreativeOverride) {
			this.forceCreativeId = parseInt(forcedCreativeOverride, 10);
		}
	}

	#getUrlOverrideEntry(key: string): string | null {
		const overrides = new URLSearchParams(window.location.search).get(key)?.split(',').map((o) => o.split(':')) ?? [],
			override = overrides.find(([name]) => name === this.name) ?? overrides.find(([name]) => name === '*');

		return override?.[1] ?? null;
	}

	pauseRefreshing() {
		if (this.refreshSettings) {
			this.refreshSettings.paused = true;
		}
	}

	resumeRefreshing() {
		if (this.refreshSettings) {
			this.refreshSettings.paused = false;
		}
	}
}

function _generateRandomSuffix(base: string) {
	return `${base}_${Math.round(Math.random() * 1e18)}`;
}
